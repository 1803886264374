import _ from "lodash";

export const pageableToQueryParams = (pageableObject) => {
    let { page = 1, sortBy=[], sortDesc=[], itemsPerPage, toFind="" } = pageableObject;
    page--;
    const options = {
        page,
        q: toFind
    };
    if(sortBy.length>0){
        let sort = sortBy[0];
        if(sortDesc[0]){
            sort+=',desc';
        }
        options.sort = sort;
    }
    if(itemsPerPage){
        options.size = itemsPerPage;
    }
    return options;
};

export const stringToNumber = (numberString) => {
    return !isNaN(numberString)?_.toNumber(numberString):0;
}
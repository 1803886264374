import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/laboratories.service';

const state = {
    pageableOptions: {
    },
    totalLabs: 0,
    labs: [],
    selectedLab: null,
};

const getters = {
    labIsSelected(state){
        return (state.selectedLab !== null);
    },
    labIsNew(state){
        return _.isEmpty(state.selectedLab);
    },
    labs(state){
        return state.labs;//possible mapping
    }
};

const actions = {
    async getAllLabs({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: labs, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setLabs', labs);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createLab({commit}, lab){
        try{
            const newLab = singleResourceProcessor(await service.create(lab));
            commit('putLab', {lab: newLab, selectLab: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editLab({commit}, {lab, id}){
        try{
            const newLab = singleResourceProcessor(await service.edit(id, lab));
            commit('putLab', {lab: newLab, selectLab: true});
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectLab(state, lab = null){
        console.log('aaa', lab);
        state.selectedLab = lab;
    },
    selectLabById(state, id){
        const selectedLab = state.labs.find(lab => lab.id === id);
        state.selectedLab = selectedLab??null;
    },
    setLabs(state, labs = []){
        state.labs = labs;
    },
    putLab(state, { lab={}, selectLab = false } = {}){
        state.labs = state.labs.map(d=>(d.id===lab.id)?lab:d);
        console.log("asss", lab);
        if(selectLab){
            state.selectedLab = lab;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalLabs = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
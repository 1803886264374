import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/lab-requests', {
            params: queryParams
        });
    },
    create(labRequest){
        return Vue.axios.post('/lab-requests', labRequest);
    },
    edit(elementId, element){
        return Vue.axios.put(`/elements/${elementId}`, element);
    },
    delete(elementId){
        return Vue.axios.delete(`/elements/${elementId}`);
    },

    getLaboratories(){
        return Vue.axios.get('/laboratories/list');
    },
    getAvailableIncomes() {
        return Vue.axios.get('/mineral-incomes/for-lab-requests');
    },
    uploadLabReportFile(formData, onUpload = ()=>{}){
        return Vue.axios.post('/files', formData, {
            onUploadProgress: onUpload
        });
    },
    saveData(id, report) {
        console.log("for edit->", report);
        return Vue.axios.put(`/lab-requests/${id}/save`, report);
    },
    confirm(id){
        return Vue.axios.patch(`/lab-requests/${id}/confirm`);
    }
};
import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import elementService  from '@/core/services/elements.service';
import moment from "moment";

const state = {
    minerals: []
};

const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

const getters = {
    mineralsData(state){
        return state.minerals.map(m=>{
            const labels = [];
            const vals = [];
            console.log(m.quotations)
            for(let it of m.quotations){
                labels.push(`${it.fortnight}|${months[it.month-1]}`);
                vals.push(it.value);
            }
           return {
               ...m,
               data: {
                   labels,
                   datasets: [{
                       data: vals,
                       label: '..'
                   }]
               }
           }
        });
    },
};

const actions = {
    async loadQuotations({commit, state}){
        try{
            const date = new moment()
            const response = await elementService.getDataByYear(date.year());
            commit('setMinerals', response.data);
        }catch(e){
            console.log(e);
            errorProcessor(e);
        }
    }
};

const mutations = {
    setMinerals(state, minerals = []){
        state.minerals = minerals;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/labRequests.service';

import {labRequestToPlainMapper} from '@/core/mappers/labRequest.mappers';

const state = {
    pageableOptions: { },
    totalLabRequests: 0,
    requests: [],
    selectedLabRequest: null,

    laboratories: [],
    incomes: []
};

const getters = {
    labRequestIsSelected(state){
        return (state.selectedLabRequest !== null);
    },
    labRequestIsNew(state){
        return _.isEmpty(state.selectedLabRequest);
    },
    labRequests(state){
        return state.requests;
    }
};

const actions = {
    async getAllRequests({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: requests, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setLabRequests', requests);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createLabRequest({commit}, request){
        try{
            const newRequest = singleResourceProcessor(await service.create(request));
            commit('putLabRequest', {request: newRequest});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async getPlainLabRequest({commit}, {request={}, isNew=false}){
        try{
            const plainRequest = labRequestToPlainMapper(request);
            const { content: labs = [] } = collectionResourceProcessor(await service.getLaboratories());
            commit('setLaboratories', labs);
            // if(!selectedSlatCode){
            //     const { content } = collectionResourceProcessor(await service.getLabs());
            //     slatCodes = content;
            // }
            return plainRequest;
        }catch(e){
            errorProcessor(e);
        }
    },

    async getAvailableIncomes({commit}){
        try{
            const { content: incomes = [] } = collectionResourceProcessor(await service.getAvailableIncomes());
            commit('setIncomes', incomes);
        }catch(e){
            errorProcessor(e);
        }
    },
    async uploadFile({commit}, {file, onUpload = ()=>{}}){
        try{
            const formData = new FormData();
            formData.append("file", file);
            formData.append("type", "labRequest");
            const response =  await service.uploadLabReportFile(formData, onUpload);
            const fileInfo = singleResourceProcessor(response);
            return fileInfo;
        }catch(e){
            errorProcessor(e);
        }
    },
    async saveLabReport({commit}, {id, report}){
        try{
            const newReport = singleResourceProcessor(await service.saveData(id, report));
            commit('putLabRequest', {request: newReport, selectRequest: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async confirmReport({commit}, id){
        try{
            const newReport = singleResourceProcessor(await service.confirm(id));
            commit('putLabRequest', {request: newReport, selectRequest: true});
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectLabRequest(state, request = null){
        state.selectedLabRequest = request;
    },
    setLabRequests(state, requests = []){
        state.requests = requests;
    },
    putLabRequest(state, { request={}, selectRequest = false } = {}){
        state.requests = state.requests.map(r=>(r.id===request.id)?request:r);
        if(selectRequest){
            state.selectedLabRequest = request;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalLabRequests = totalElements;
    },

    setLaboratories(state, labs = []){
        state.laboratories = labs;
    },
    setIncomes(state, incomes = []){
        state.incomes = incomes;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
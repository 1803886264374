import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/transactions.service';

const state = {
    pageableOptions: {
    },
    totalTransactions: 0,
    transactions: [],
    selectedTransaction: null,
    transactionType: null,

    cashDesks: [],
    accounts: [],
    providers: [],
};

const getters = {
    transactionIsSelected(state){
        return (state.selectedTransaction !== null);
    },
    transactionIsNew(state){
        return _.isEmpty(state.selectedTransaction);
    },
    transactions(state){
        return state.transactions;//possible mapping
    },
    cashDesks(state){
        return state.cashDesks.map(cd=>({
            id: cd.id,
            name: cd.name
        }));
    },
    accounts(state){
        const result = [];
        state.accounts.forEach(ac=>{
            result.push({
               header: ac.name
            });
            (ac.subAccounts||[]).forEach(sa=>{
               result.push(sa);
            });
        });
        return result;
    },
    providers(state){
        return state.providers.map(p=>({
            name: p.name,
            id: p.id
        }));
    }
};

const actions = {
    async getAllTransactions({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: transactions, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setTransactions', transactions);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createTransaction({commit}, transaction){
        try{
            const newTransaction = singleResourceProcessor(await service.create(transaction));
            commit('putTransaction', {transaction: newTransaction, selectTransaction: true});
        }catch(e){
            errorProcessor(e);
        }
    },
    async editTransaction({commit}, {transaction, id}){
        try{
            const newTransaction = singleResourceProcessor(await service.edit(id, transaction));
            commit('putTransaction', {transaction: newTransaction, selectTransaction: true});
        }catch(e){
            errorProcessor(e);
        }
    },
    async getAllCashDesks({commit}){
        try{
            const {content: cashDesks} = collectionResourceProcessor(await service.getCashDesks());
            commit('setCashDesks', cashDesks);
        }catch (e){
            errorProcessor(e);
        }
    },
    async getAllAccounts({commit}){
        try{
            const {content: accounts} = collectionResourceProcessor(await service.getAccounts());
            commit('setAccounts', accounts);
        }catch (e){
            errorProcessor(e);
        }
    },
    async getCompleteTransaction({commit, state}, {isNew= true}){
        const transaction = state.selectedTransaction;
        if(isNew)
            return {};
        const trans = singleResourceProcessor(await service.get(transaction.id));
        commit('setProviders', trans.owner?[trans.owner]:[]);
        return trans;
    },
    async getProviders({commit}, query){
        try{
            const {content: providers} = collectionResourceProcessor(await service.getProviders(query));
            commit('setProviders', providers);
        }catch(e){
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectTransaction(state, {transaction=null, type = null} = {}){
        state.selectedTransaction = transaction;
        state.transactionType = type;
    },
    selectTransactionById(state, id){
        const selectedTransaction = state.transactions.find(transaction => transaction.id === id);
        state.selectedTransaction = selectedTransaction??null;
    },
    setTransactions(state, transactions = []){
        state.transactions = transactions;
    },
    putTransaction(state, { transaction={}, selectTransaction = false } = {}){
        state.transactions = state.transactions.map(t=>(t.id===transaction.id)?transaction:t);
        if(selectTransaction){
            state.selectedTransaction = transaction;
        }
    },
    setCashDesks(state, cashDesks = []) {
        state.cashDesks = cashDesks;
    },
    setAccounts(state, accounts = []) {
        state.accounts = accounts;
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalTransactions = totalElements;
    },

    setProviders(state, providers = []){
        state.providers = providers;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/cash-desks', {
            params: queryParams
        });
    },
    create(cashDesk){
        return Vue.axios.post('/cash-desks', cashDesk);
    },
    edit(cashDeskId, cashDesks){
        return Vue.axios.put(`/cash-desks/${cashDeskId}`, cashDesks);
    },
    delete(cashDeskId){
        return Vue.axios.delete(`/cash-desks/${cashDeskId}`);
    },
};
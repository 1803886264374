import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/contributions.service';

const state = {
    pageableOptions: {
    },
    totalContributions: 0,
    contributions: [],
    selectedContribution: null,
};

const getters = {
    contributionIsSelected(state){
        return (state.selectedContribution !== null);
    },
    contributionIsNew(state){
        return _.isEmpty(state.selectedContribution);
    },
    contributions(state){
        return state.contributions;//possible mapping
    }
};

const actions = {
    async getAllContributions({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            console.log(queryParams);
            const {content: contributions, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setContributions', contributions);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createContribution({commit}, contribution){
        try{
            const newContribution = singleResourceProcessor(await service.create(contribution));
            commit('putContribution', {contribution: newContribution, selectContribution: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editContribution({commit}, {contribution, id}){
        try{
            const newContribution = singleResourceProcessor(await service.edit(id, contribution));
            commit('putContribution', {contribution: newContribution, selectContribution: true});
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectContribution(state, contribution = null){
        state.selectedContribution = contribution;
    },
    selectContributionById(state, id){
        const selectedContribution = state.contributions.find(contribution => contribution.id === id);
        state.selectedContribution = selectedContribution??null;
    },
    setContributions(state, contributions = []){
        state.contributions = contributions ;
    },
    putContribution(state, { contribution={}, selectContribution = false } = {}){
        state.contributions = state.contribution.map(d=>(d.id===contribution.id)?contribution:d);
        if(selectContribution){
            state.selectedContribution = contribution;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalContributions = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

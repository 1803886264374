import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/cashdesk.service';

const state = {
    pageableOptions: {
    },
    totalCashDesks: 0,
    cashDesks: [],
    selectedCashDesk: null,
};

const getters = {
    cashDeskIsSelected(state){
        return (state.selectedCashDesk !== null);
    },
    cashDeskIsNew(state){
        return _.isEmpty(state.selectedCashDesk);
    },
    cashDesks(state){
        return state.cashDesks;//possible mapping
    }
};

const actions = {
    async getAllCashDesks({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: cashDesks, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setCashDesks', cashDesks);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createCashDesk({commit}, cashdesk){
        try{    
            const newCashDesk = singleResourceProcessor(await service.create(cashdesk));
            commit('putCashDesk', {cashDesk: newCashDesk, selectCashDesk: true});
        }catch(e) {
            errorProcessor(e);
        }
    },  
    async editCashDesk({commit}, {cashDesk, id}){
        try{
            const newCashDesk = singleResourceProcessor(await service.edit(id, cashDesk));
            commit('putCashDesk', {cashDesk: newCashDesk, selectCashDesk: true});
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectCashDesk(state, cashDesk = null){
        state.selectedCashDesk = cashDesk;
    },
    selectCashDeskById(state, id){
        const selectedCashDesk = state.cashDesks.find(cashDesk => cashDesk.id === id);
        state.selectedCashDesk = selectedCashDesk??null;
    },
    setCashDesks(state, cashDesks = []){
        state.cashDesks = cashDesks;
    },
    putCashDesk(state, { cashDesk={}, selectCashDesk = false } = {}){
        state.cashDesks = state.cashDesk.map(d=>(d.id===cashDesk.id)?cashDesk:d);
        if(selectCashDesk){
            state.selectedCashDesk = cashDesk;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalCashDesks = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
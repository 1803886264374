import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/products.service';

const state = {
    pageableOptions: {
    },
    totalProducts: 0,
    products: [],
    selectedProduct: null,
};

const getters = {
    productIsSelected(state){
        return (state.selectedProduct !== null);
    },
    productIsNew(state){
        return _.isEmpty(state.selectedProduct);
    },
    products(state){
        return state.products;//possible mapping
    }
};

const actions = {
    async getAllProducts({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: products, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setProducts', products);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createProduct({commit}, product){
        try{
            const newProduct = singleResourceProcessor(await service.create(product));
            commit('putProduct', {product: newProduct, selectProduct: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editProduct({commit}, {product, id}){
        try{
            const newProduct = singleResourceProcessor(await service.edit(id, product));
            commit('putProduct', {product: newProduct, selectProduct: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async getMinerals(){
        try{
            const { content: minerals } = collectionResourceProcessor(await service.getMinerals());
            return minerals;
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectProduct(state, product = null){
        state.selectedProduct = product;
    },
    selectProductById(state, id){
        const selectedProduct = state.products.find(product => product.id === id);
        state.selectedProduct = selectedProduct??null;
    },
    setProducts(state, products = []){
        state.products = products;
    },
    putProduct(state, { product={}, selectProduct = false } = {}){
        state.products = state.products.map(d=>(d.id===product.id)?product:d);
        if(selectProduct){
            state.selectedProduct = product;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalProducts = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/users.service';

const state = {
    pageableOptions: {
    },
    totalUsers: 0,
    users: [],
    selectedUser: null,

    roles: []
};

const getters = {
    userIsSelected(state){
        return (state.selectedUser !== null);
    },
    userIsNew(state){
        return _.isEmpty(state.selectedUser);
    },
    users(state){
        return state.users;//possible mapping
    }
};

const actions = {
    async getAllUsers({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: users, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setUsers', users);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createUser({commit}, user){
        try{
            const newUser = singleResourceProcessor(await service.create(user));
            commit('putUser', {user: newUser, selectUser: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editUser({commit}, {user, id}){
        try{
            const newUser = singleResourceProcessor(await service.edit(id, user));
            commit('putUser', {user: newUser, selectUser: true});
        }catch(e) {
            errorProcessor(e);
        }
    },

    async getRoles({commit}){
        try{
            const {content: roles} = collectionResourceProcessor(await service.getRoles());
            commit('setRoles', roles);
        }catch (e){
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectUser(state, user = null){
        state.selectedUser = user;
    },
    setUsers(state, users = []){
        state.users = users;
    },
    setRoles(state, roles = []){
        state.roles = roles;
    },
    putUser(state, { user={}, selectUser = false } = {}){
        state.users = state.users.map(t=>(t.id===user.id)?user:t);
        if(selectUser){
            state.selectedUser = user;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalUsers = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
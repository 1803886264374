export default [
    {
        title: 'INICIO',
        icon: 'mdi-home',
        roles: [],
        path: '/'
    },
    {
        title: 'GENERAL',
        icon: 'mdi-database-settings',
        roles: [],
        children: [
            {
                title: 'Adm. de camiones',
                icon: 'mdi-dump-truck',
                roles: [],
                path: '/trucks'
            },
            {
                title: 'Adm. de conductores',
                icon: 'mdi-steering',
                roles: [],
                path: '/drivers'
            },
            {
                title: 'Adm. de cooperativas',
                icon: 'mdi-account-group',
                roles: [],
                path: '/cooperatives'
            },
            {
                title: 'Adm. de elementos',
                icon: 'mdi-dots-hexagon',
                roles: [],
                path: '/elements'
            },
            {
                title: 'Adm. de productos',
                icon: 'mdi-sack',
                roles: [],
                path: '/products'
            },
            {
                title: 'Adm. de minas',
                icon: 'mdi-image-filter-hdr',
                roles: [],
                path: '/mineMouth'
            },
            {
                title: 'Adm. de proveedores',
                icon: 'mdi-account-tie',
                roles: [],
                path: '/providers'
            },
            {
                title: 'Adm. de Retenciones',
                icon: 'mdi-currency-usd',
                roles: [],
                path: '/contribution'
            },
        ]
    },
    {
        title: 'Ingresos',
        icon: 'mdi-dump-truck',
        roles: [],
        path: '/mineral-incomes'
    },
    {
        title: 'Liquidaciones',
        icon: 'mdi-cash-100',
        roles: [],
        path: '/liquidations',
    },
    {
        title: 'Salidas',
        icon: 'mdi-export',
        roles: [],
        path: '/mineral-outcomes',
    },
    {
        title: 'LABORATORIOS',
        icon: 'mdi-flask',
        roles: [],
        children: [
            {
                title: 'Solicitudes de laboratorio',
                icon: 'mdi-file-chart',
                roles: [],
                path: '/lab-requests',
            },
            {
                title: 'Adm. de laboratorios',
                icon: 'mdi-flask',
                roles: [],
                path: '/laboratories',
            }
        ]
    },
    {
        title: 'CAJAS',
        icon: 'mdi-cash-register',
        roles: [],
        children: [
            {
              title: 'Transacciones',
              icon: 'mdi-cash-multiple',
              roles: [],
              path: '/transactions'
            },
            {
                title: 'Adm. de cajas',
                icon: 'mdi-cash-register',
                roles: [],
                path: '/cashDesk'
            },
            {
                title: 'Adm. de cuentas',
                icon: 'mdi-file-table-box',
                roles: [],
                path: '/accounts'
            },
        ]
    },
    {
        title: 'REPORTES',
        icon: 'mdi-chart-bar',
        roles: [],
        children: [
            {
                title: 'Example',
                icon: 'mdi-file-chart',
                roles: [],
                path: '/'
            },
            {
                title: 'M02',
                icon: 'mdi-chart-bar',
                roles: [],
                path: '/reports/m02'
            }
        ],
    },
    {
        title: 'CONFIGURACIONES',
        icon: 'mdi-cog',
        roles: [],
        children: [
            {
                title: 'Codigos de Tablilla',
                icon: 'mdi-code-string',
                roles: [],
                path: '/slatCode'
            },
            {
                title: 'Adm. de usuarios',
                icon: 'mdi-account-key',
                roles: [],
                path: '/users'
            },
            {
                title: 'Datos Empresa',
                icon: 'mdi-home-city',
                roles: [],
                path: '/companie'
            }
        ],
    },
];
import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.module';
import drivers from './drivers.module';
import trucks from './trucks.module';
import providers from './providers.module';
import incomes from './incomes.module';
import cooperatives from './cooperatives.module';
import mineMouths from './mines.module';
import liquidations from './liquidations.module';
import slatCodes from './slatcodes.module';
import cashDesk from './cashdesk.module';
import contributions from './contributions.module'
import accounts from './accounts.module';
import elements from './elements.module';
import laboratories from './laboratories.module';
import labRequests from './labRequests.module';
import companies from './companies.module'
import transactions from './transactions.module';
import users from './users.module';
import products from './products.module';
import outcomes from './outcomes.module';
import home from './home.module';

import {EMPTY_LAYOUT} from "@/shared/utils/constants";
import {SET_LAYOUT} from './_mutations.type';

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    MAIN_LAYOUT: EMPTY_LAYOUT
  },
  mutations: {
    [SET_LAYOUT](state, layout){
      state.MAIN_LAYOUT = layout;
    }
  },
  actions: {
  },
  modules :{
    auth,
    drivers,
    cooperatives,
    mineMouths,
    trucks,
    providers,
    incomes,
    outcomes,
    slatCodes,
    cashDesk,
    liquidations,
    contributions,
    accounts,
    elements,
    laboratories,
    labRequests,
    companies,
    transactions,
    users,
    products,
    home
  }
})

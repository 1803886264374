import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/slatcodes.service';

const state = {
    pageableOptions: {
    },
    totalSlatCodes: 0,
    slatCodes: [],
    selected:[],
    dialogState:false
    //selectedSlatCode: null,
};

const getters = {
    /*cooperativeIsSelected(state){
        return (state.selectedCooperative !== null);
    },
    cooperativeIsNew(state){
        return _.isEmpty(state.selectedCooperative);
    },*/
    slatCodes(state){
        return state.slatCodes;//possible mapping
    },
    dialogState(state){
        return state.dialogState
    }
};

const actions = {
    async getAllSlatCodes({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: slatCodes, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setSlatCodes', slatCodes);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async generateCodes({commit}, cantidad)
    {
        try{       
            const {content: slatCodes, meta} = collectionResourceProcessor(await service.generateCodes(cantidad));
            commit('setSlatCodes', slatCodes);
            commit('setMetaData',meta);

        }catch(e){
            errorProcessor(e);
        }
    },
    async printCodes({commit},codes)
    {
        //try{
            const response= await service.printCodes(codes);
            const {data = ""} = response;
            return data;
        //}catch(e){
          //  errorProcessor(e);
        //}
    }
    /*async createCooperative({commit}, cooperative){
        try{
            const newCooperative = singleResourceProcessor(await service.create(cooperative));
            commit('putCooperative', {cooperative: newCooperative, selectCooperative: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editCooperative({commit}, {cooperative, id}){
        try{
            const newCooperative = singleResourceProcessor(await service.edit(id, cooperative));
            commit('putCooperative', {cooperative: newCooperative, selectCooperative: true});
        }catch(e) {
            errorProcessor(e);
        }
    }*/
};

const mutations = {
    /*selectCooperative(state, cooperative = null){
        state.selectedCooperative = cooperative;
    },
    selectCooperativeById(state, id){
        const selectedCooperative = state.cooperatives.find(cooperative => cooperative.id === id);
        state.selectedCooperative = selectedCooperative??null;
    },*/
    setSlatCodes(state, slatCodes = []){
        state.slatCodes = [];
        state.slatCodes = slatCodes;
    },
    /*putCooperative(state, { cooperative={}, selectCooperative = false } = {}){
        state.cooperatives = state.cooperatives.map(d=>(d.id===cooperative.id)?cooperative:d);
        if(selectCooperative){
            state.selectedCooperative = cooperative;
        }
    },*/
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalCooperatives = totalElements;
    },
    setSelectedRows(state, selected){
        state.selected = selected;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

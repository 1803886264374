const JWT_TOKEN_KEY = 'DN_TOKEN';
const USER_KEY = 'DN_USER';
const AUTHORITIES_KEY = 'DN_AUTHORITIES'

import Vue from 'vue';

export default {
    login(credentials = {}){
        return Vue.axios.post('/auth/login', credentials);
    },
    signOut(){
        localStorage.removeItem(JWT_TOKEN_KEY);
        localStorage.removeItem(USER_KEY);
        localStorage.removeItem(AUTHORITIES_KEY);
    },
    signIn(token, user, authorities){
        localStorage.removeItem(JWT_TOKEN_KEY);
        localStorage.setItem(JWT_TOKEN_KEY, token);

        localStorage.removeItem(USER_KEY);
        localStorage.setItem(USER_KEY, user);

        localStorage.removeItem(AUTHORITIES_KEY);
        localStorage.setItem(AUTHORITIES_KEY, authorities)
    },
    getToken() {
        return localStorage.getItem(JWT_TOKEN_KEY);
    },
    getUser(){
        return localStorage.getItem(USER_KEY);
    },
    getRoles(){
        return localStorage.getItem(AUTHORITIES_KEY);
    }
};
import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/transactions', {
            params: queryParams
        });
    },
    get(id){
        return Vue.axios.get(`/transactions/${id}`);
    },
    create(transaction){
        return Vue.axios.post('/transactions', transaction);
    },
    edit(id, transaction){
        return Vue.axios.put(`/transactions/${id}`, transaction);
    },
    getCashDesks(){
        return Vue.axios.get('/cash-desks/list');
    },
    getAccounts(){
        return Vue.axios.get('/accounts/list');
    },
    getProviders(query) {
        return Vue.axios.get('/providers/search', {
            params: {q:query}
        });
    },
};
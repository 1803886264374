import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/laboratories', {
            params: queryParams
        });
    },
    create(lab){
        return Vue.axios.post('/laboratories', lab);
    },
    edit(labId, lab){
        return Vue.axios.put(`/laboratories/${labId}`, lab);
    }
};
import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/elements.service';

const state = {
    pageableOptions: {},
    totalQuotations: 0,
    elements: [],
    quotations: [],
    selectedElement: null,
};

const getters = {
    elementIsSelected(state){
        return (state.selectedElement !== null);
    },
    elementIsNew(state){
        return _.isEmpty(state.selectedElement);
    },
    elements(state){
        return state.elements;//possible mapping
    }
};

const actions = {
    async getAllElements({commit, state}){
        try{
            //const queryParams = pageableToQueryParams(state.pageableOptions??{});
            //console.log(queryParams);
            const {content: elements} = collectionResourceProcessor(await service.getAll());
            commit('setElements', elements);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getQuotationsForElement({commit, state}, element){
        try{
            const {id='none'} = element;
            commit('setQuotations');
            if(id!=='none'){
                const queryParams = pageableToQueryParams(state.pageableOptions??{});
                const {content: quotations, meta = {}} = collectionResourceProcessor(await service.quotations(id, queryParams));
                commit('setQuotations', quotations);
                commit('setMetaData', meta);
            }
        }catch(e){
            console.log(e);
            errorProcessor(e);
        }
    },
    async deleteQuotation({commit, state}, quotation){
        try{
            const {id='none'} = quotation;
            if(id!=='none'){
                await service.deleteQuotation(id);
                commit('removeQuotation', quotation);
            }
        }catch(e){
            errorProcessor(e);
        }
    },
    async saveManyQuotations({commit}, quotationsForm){
        try{
            await service.saveManyQuotations(quotationsForm);
        }catch(e){
            console.log(e);
            errorProcessor(e);
        }
    },
    async createElement({commit}, element){
        try{
            const newElement = singleResourceProcessor(await service.create(element));
            commit('putElement', {element: newElement, selectElement: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editElement({commit}, {element, id}){
        try{
            const newElement = singleResourceProcessor(await service.edit(id, element));
            commit('putElement', {element: newElement, selectElement: true});
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectElement(state, element = null){
        state.selectedElement = element;
    },
    selectElementById(state, id){
        const selectedElement = state.elements.find(element => element.id === id);
        state.selectedElement = selectedElement??null;
    },
    setElements(state, elements = []){
        state.elements = elements;
    },
    setQuotations(state, quotations = []){
        state.quotations = quotations;
    },
    removeQuotation(state, quotation = {}){
        const { id = 'none' } = quotation;
        state.quotations = state.quotations.filter(q=>q.id!==id);
    },
    putElement(state, { element={}, selectElement = false } = {}){
        state.elements = state.elements.map(d=>(d.id===element.id)?element:d);
        if(selectElement){
            state.selectedElement = element;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalQuotations = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

export const singleResourceProcessor = (response) => {
    const { data = {} } = response;
    const { _content = {} } = data;
    return _content;
};

export const collectionResourceProcessor = (response) => {
    const { data = {} } = response;
    const { _content = [], _meta = {} } = data;
    return {
        content: _content,
        meta: _meta
    };
};

export const errorProcessor = (error) => {
    const { response={} } = error;
    const {status=0, data={}} = response;
    const {message = 'Se ha producido un problema procesando la solicitud', errors = {}} = data;
    let errorObject = {
        title: 'Atencion',
        type: 'warning',
        message,
        errors
    };//default
    if(status === 422){
        errorObject.type = 'warning';
        errorObject.title = "Datos inválidos"
    }else if(status>=500){
        errorObject.type = 'error';
    }
    throw errorObject;
}
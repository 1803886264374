import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/mineral-incomes', {
            params: queryParams
        });
    },
    getExportable(){
        return Vue.axios.get('/mineral-incomes/exportable');
    },
    findByCode(code){
        return Vue.axios.get('/mineral-incomes/by-code', {
            params: {
                code: code
            }
        });
    },
    create(income){
        return Vue.axios.post('/mineral-incomes', income);
    },
    edit(incomeId, income){
        return Vue.axios.put(`/mineral-incomes/${incomeId}`, income);
    },
    delete(incomeId){
        return Vue.axios.delete(`/mineral-incomes/${incomeId}`);
    },

    getCollectionCenters(query) {
        return Vue.axios.get('/collection-centers/search', {
            params: {q:query}
        });
    },
    getPlatforms(query) {
        return Vue.axios.get('/platforms/search', {
            params: {q:query}
        });
    },
    getProviders(query) {
        return Vue.axios.get('/providers/search', {
            params: {q:query}
        });
    },
    getCooperatives(query) {
        return Vue.axios.get('/cooperatives/search', {
            params: {q:query}
        });
    },
    getMineMouths(query) {
        return Vue.axios.get('/mines/search', {
            params: {q:query}
        });
    },
    getDrivers(query) {
        return Vue.axios.get('/drivers/search', {
            params: {q:query}
        });
    },
    getTrucks(query) {
        return Vue.axios.get('/trucks/search', {
            params: {q:query}
        });
    },
    getAvailableSlatCodes(query) {
        return Vue.axios.get('/slat-codes/available');
    },
    getProducts(query) {
        return Vue.axios.get('/products/list');
    },
    getMunicipalitys(query){
        return Vue.axios.get('/municipalitys/list');
    },
    printReportIncome(income){
        return Vue.axios.get(`/mineral-incomes/printReportIncome/${income}`,{
            responseType: "arraybuffer"
        });
    },
    saveProvider(provider){
        return Vue.axios.post('/mineral-incomes/createProvider',provider);
    },
    saveCooperative(cooperative){
        return Vue.axios.post('/mineral-incomes/createCooperative',cooperative);
    },
    saveTruck(truck){
        return Vue.axios.post('/mineral-incomes/createTruck',truck);
    },
    saveDriver(driver){
        return Vue.axios.post('/mineral-incomes/createDriver',driver);
    },
    saveMine(mineMouth,id){ 
        return Vue.axios.post(`mineral-incomes/createMine/${id}`, mineMouth);
    }
};
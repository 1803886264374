import Vue from 'vue';

export default {
    getAll(){
        return Vue.axios.get('/companies');
    },
    create(companie){
        return Vue.axios.post('/companies', companie);
    },
    edit(companieId, companie){
        return Vue.axios.put(`/companies/${companieId}`, companie);
    },
    delete(companieId){
        return Vue.axios.delete(`/companies/${companieId}`);
    }
};
import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/companies.service';

const state = { 
    companies: [],
    selectedCompanie: null,
};

const getters = {
    companieIsSelected(state){
        return (state.selectedCompanie !== null);
    },
    companieIsNew(state){
        return _.isEmpty(state.selectedCompanie);
    },
    companies(state){
        return state.companies;//possible mapping
    }
};

const actions = {
    async getAllCompanies({commit, state}){
        try{
            const {content: companies, meta} = collectionResourceProcessor(await service.getAll());
            commit('setCompanies', companies);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createCompanie({commit}, companie){
        try{
            const newCompanie = singleResourceProcessor(await service.create(companie));
            commit('putCompanie', {companie: newCompanie, selectCompanie: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editCompanie({commit}, {companie, id}){
        try{
            const newCompanie = singleResourceProcessor(await service.edit(id, companie));
            commit('putCompanie', {companie: newCompanie, selectCompanie: true});
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectCompanie(state, companie = null){
        state.selectedCompanie = companie;
    },
    selectCompanieById(state, id){
        const selectedCompanie = state.companies.find(companie => companie.id === id);
        state.selectedCompanie = selectedCompanie??null;
    },
    setCompanies(state, companies = []){
        state.companies = companies;
    },
    putCompanie(state, { companie={}, selectCompanie = false } = {}){
        state.companies = state.companies.map(d=>(d.id===companie.id)?companie:d);
        if(selectCompanie){
            state.selectedCompanie = companie;
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/mines.service';
import { integer } from 'vuelidate/lib/validators';

const state = {
    pageableOptions: {
    },
    totalMineMouths: 0,
    municipalitys: [],
    mineMouths: [],
    miningConcessions:[],
    mineMouthConcessions:[],
    selectedMineMouth: null
};

const getters = {
    mineMouthIsSelected(state){
        return (state.selectedMineMouth !== null);
    },
    mineMouthIsNew(state){
        return _.isEmpty(state.selectedMineMouth);
    },
    mineMouths(state){
        return state.mineMouths;//possible mapping
    },
    municipalitys(state){
        return state.municipalitys.map(cc=>({
            text: cc.name,
            value: cc.id,
            locations: cc.locations
        }));
    },
    locations(state){
        return state.locations.map(cc=>({
            text: cc.name,
            value: cc.id,
        }));
    },
    miningConcessions(state){
        return state.miningConcessions.map(cc=>({
            text: cc.name,
            value:cc.id
        }));
    },
    mineMouthConcessions(state){
        return state.mineMouthConcessions.map(cc=>({
            text: cc.name,
            value:cc.id
        }));
    },
};

const actions = {
    async getAllMineMouths({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content : content, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setMineMouths', content);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getMunicipalitys({commit}, query){
        try{
            const {content: municipalitys} = collectionResourceProcessor(await service.getMunicipalitys(query));
            commit('setMunicipalitys', municipalitys);
        }catch(e){
            errorProcessor(e);
            console.log(e);
        }
    },
    async getMiningConcessionMunicipality({commit}, municipalityId){
        try{
            //const {content: miningConcessions} = await service.getMunicipalitys(query);
            const response = await service.getMiningConcessions(municipalityId);
            //console.log("valoresss",response.data._content);
            commit('setMiningConcessions', response.data._content);
        }catch(e){
            errorProcessor(e);
        }
    },
    async saveMiningConcession({commit}, miningConcession){
        try{
            const response = await service.createMiningConcession(miningConcession);
            console.log(response.data._content.miningConcessions);
            commit('setMiningConcessions', response.data._content.miningConcessions);
        }catch(e){
            console.log("error");
        }
    },
    async getMineMining({commit},miningConcessionId){
        try{
            const response = await service.getMineMining(miningConcessionId);
            //console.log("valoresss",response.data._content);
            commit('setMineMouthConcessions', response.data._content);
        }catch(e){
            console.log("error");
        }
    },
    async saveMineMouthConcession({commit},mineMouth){
        try{
            console.log(mineMouth,"objeto valor")
            const response = await service.createMineMouthConcession(mineMouth);
            console.log(response.data._content.mineMouthConcessions);
            
            console.log(response.data._content.mineMouthConcessions,"listaaaa");
            commit('setMineMouthConcessions', response.data._content.mineMouthConcessions);
        }catch(e){
            console.log("error");
        }
    }
};

const mutations = {
    selectMineMouth(state, mineMouth = null){
        state.selectedMineMouth = mineMouth;
    },
    selectMineMouthById(state, id){
        const selectedMineMouth = state.mineMouths.find(mineMouth => mineMouth.id === id);
        state.selectedMineMouth = selectedMineMouth??null;
    },
    setMineMouths(state, mineMouths = []){
        state.mineMouths = mineMouths;
    },
    putMineMouth(state, { mineMouth={}, selectMineMouth = false } = {}){
        state.mineMouths = state.mineMouths.map(d=>(d.id===mineMouth.id)?mineMouth:d);
        if(selectMineMouth){
            state.selectedMineMouth = mineMouth;
        }
    },
    putMineMouthCuztom(state,{ mineMouthCuztom={},selectedMineMouth = false} = {}){
        state.mineMouth = state.mineMouths.map(d=>(d.id===mineMouthCuztom.id)?mineMouthCuztom:d);
        if(selectedMineMouth){
            state.selectMineMouth = mineMouthCuztom;
        }
    },
    setMunicipalitys(state, municipalitys = []){
        state.municipalitys = municipalitys;
    },
    setLocations(state,municipalitys){
        return municipalitys.map(cc=>(
            state.locations = cc.locations
        ))
    },
    setMiningConcessions(state,miningConcessions = []){
        state.miningConcessions = miningConcessions;
    },
    setMineMouthConcessions(state, mineMouthConcessions){
        state.mineMouthConcessions = mineMouthConcessions;
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalMineMouths = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
import service from '@/core/services/auth.service';
import {SET_ERRORS, PURGE_AUTH, SET_AUTH} from "./_mutations.type";
import {errorProcessor, singleResourceProcessor} from "@/shared/utils/response-processors.helper";

const state = {
    errors: {},
    user: service.getUser()||"",
    isAuth: !!service.getToken(),
    roles: service.getRoles()||[]
};

const getters = {

};

const actions = {
    async logout({commit}){
        try{
            service.signOut();
        }catch (e){
            console.error(e);
        }

    },
    async login({commit}, {username, password}){
        try{
            const credentials = { username, password };
            const resp = singleResourceProcessor(await service.login(credentials));
            //alert(JSON.stringify(resp));
            const {authorities, token, user} = resp;
            service.signIn(token , user, authorities);
        }catch(e){
            errorProcessor(e);
        }
    }
};

const mutations = {
    [SET_ERRORS](state, errors){
        state.errors = errors;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
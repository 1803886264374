import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/elements', {
            params: queryParams
        });
    },
    create(element){
        return Vue.axios.post('/elements', element);
    },
    edit(elementId, element){
        return Vue.axios.put(`/elements/${elementId}`, element);
    },
    delete(elementId){
        return Vue.axios.delete(`/elements/${elementId}`);
    },

    quotations(elementId, queryParams = {}){
        return Vue.axios.get(`/quotations/by-element/${elementId}`, {
            params: queryParams
        });
    },
    quotationsByDate(date){
        return Vue.axios.get(`elements/quotations`, {
            params: {
                date: date
            }
        });
    },
    getDataByYear(year){
        return Vue.axios.get(`elements/by-year`, {
            params: {
                year
            }
        });
    },
    deleteQuotation(quotationId){
        return Vue.axios.delete(`/quotations/${quotationId}`);
    },

    saveManyQuotations(form){
        return Vue.axios.post('/quotations/many', form);
    }
};
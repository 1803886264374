<template>
  <div id="app">
    <v-app id="inspire">
      <v-app>
        <v-app-bar
                app
                color="primary"
                dark
        >
          <span class="title ml-3 mr-5">DNubia v1.0</span>
          <v-spacer></v-spacer>
          <v-btn color="tertiary" @click="onLogout">
            <v-icon left>
              mdi-logout
            </v-icon>
            Salir
          </v-btn>
        </v-app-bar>

        <v-navigation-drawer
                :value="true"
                app
                color="primary"
                dark
                mini-variant
                mini-variant-width="110"
        >
          <v-list
            dense nav
          >
            <div v-for="(item, index) of menuItems" :key="'main_'+index">
              <v-menu
              link
                open-on-hover
                offset-x
                transition="slide-x-transition"
                v-if="item.children&&item.children.length>0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item link
                    v-bind="attrs"
                    v-on="on"
                    >
                    <v-list-item-content>
                      <v-icon class="mb-1 mt-1">{{item.icon}}</v-icon>
                      <v-list-item-subtitle align="center" class="text-uppercase"
                      style="font-size: 0.55rem !important"
                      >
                        {{item.title}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>

                <v-list nav dense>
                  <v-subheader>{{item.title}}</v-subheader>
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(child, subIndex) in item.children"
                      :key="'sub_'+index+'_'+subIndex"
                      :to="child.path"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon v-text="child.icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-list-item link :to="item.path" v-else>
                <v-list-item-content>
                  <v-icon class="mb-1 mt-1">{{item.icon}}</v-icon>
                  <v-list-item-subtitle align="center" class="text-uppercase"
                  style="font-size: 0.55rem !important"
                  >
                    {{item.title}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-navigation-drawer>

        <v-main>
          <v-container fluid class="d-flex flex-column fill-height align-stretch px-2 py-0" style="background-color: #f0f2f5;">
            <router-view></router-view>
          </v-container>
        </v-main>
      </v-app>
    </v-app>
  </div>
</template>

<script>
import menu from '@/router/options.menu';
import {createNamespacedHelpers} from 'vuex'
const  {mapActions} = createNamespacedHelpers('auth');
export default {
  name: 'UserApp',
  props: {
  },
  components: {
  },
  data: () => ({
      menuItems: menu
    }),
  methods: {
    ...mapActions(['logout']),
    onLogout(){
      const self = this;
      self.logout().then(()=>window.location.reload()).finally(()=>console.log('exit'));
    }
  }
};
</script>

import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/liquidations.service';
import elementService from '@/core/services/elements.service';
import cooperativeService from '@/core/services/cooperatives.service';

import { liquidationToPlainMapper } from '@/core/mappers/liquidationMappers';

const state = {
    liquidations: [],
    pageableOptions: {
    },
    totalLiquidations: 0,
    selectedLiquidation: null,
    
    selection: {},
    liquidation: {},

    incomes: [],
    cooperatives: [],
    providers: [],
    owners: [],
    mineMouths: [],
    minerals: [],
    slatCodes: [],

    labResults: []
};

const getters = {
    liquidationIsSelected(state){
        return (state.selectedLiquidation !== null);
    },
    liquidationIsNew(state){
        return _.isEmpty(state.selectedLiquidation);
    },
    liquidationIsOriginal(state){
        const { incomeId = 'none', type = 'ORIGINAL' } = state.selectedLiquidation;
        return (type === 'ORIGINAL');
    },
    incomes(state){
        return state.incomes;//possible mapping
    },
    liquidations(state){
        return state.liquidations;//possible mapping
    },

    cooperatives(state){
        return state.cooperatives.map(c=>({
            text: c.name,
            value: c.id
        }));
    },
    owners(state){
        return state.owners.map(sp=>({
            text: sp.name,
            value: sp.id
        }));
    },
    mineMouths(state){
        return state.mineMouths.map(mm=>({
            text: mm.name,
            value: mm.id
        }));
    },
    slatCodes(state){
        return state.slatCodes.map(s=>({
            text: s.code,
            value: s.id
        }));
    }
};

const actions = {
    async getShortIncomesBySearch({commit}, query){
        try{
            const {content: incomes} = collectionResourceProcessor(await service.getShortIncomesBySearch(query));
            commit('setIncomes', incomes);
        }catch(e){
            console.log(e);
            errorProcessor(e);
        }
    },
    async getContributionsByCooperative({commit, state}, cooperativeId){
        let contribs = [];
        try{
            const { content } = collectionResourceProcessor(await cooperativeService.getCooperativeContrib(cooperativeId));
            const { cooperativeContributions = []} = content;
            contribs = cooperativeContributions;
        }catch (e){
            errorProcessor(e)
        }
        return contribs;
    },
    async getAllLiquidations({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: liquidations, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setLiquidations', liquidations);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getLiquidationByIncome({commit, state}){
        try{
            const {selectedLiquidation = {}} = state;
            const incomeId = selectedLiquidation.incomeId??'none';
            const type = selectedLiquidation.type??'ORIGINAL';
            let liquidation = {};
            if(type === 'COPY'){
                liquidation = singleResourceProcessor(await service.getCopyLiquidationByIncomeId(incomeId));
            }else{
                liquidation = singleResourceProcessor(await service.getOriginalLiquidationByIncomeId(incomeId));
            }
            commit('setLiquidation', liquidation);
            commit('setCooperatives', liquidation.cooperatives??[]);
            const {content: minerals = [] } = collectionResourceProcessor(await service.getMinerals());
            commit('setMinerals', minerals);
        }catch(e){
            console.log("error", e);
            errorProcessor(e);
        }
    },
    async getPlainLiquidation({commit, state}){
        const liquidation = state.liquidation;
        const plainLiquidation = liquidationToPlainMapper(liquidation);
        //commit('setCooperatives', liquidation.cooperative?[liquidation.cooperative]:[]);
        commit('setProviders', liquidation.provider? [liquidation.provider]:[]);
        commit('setOwners', liquidation.owner? [liquidation.owner]:[]);
        commit('setMineMouths', liquidation.mineMouth? [liquidation.mineMouth]:[]);
        commit('setSlatCodes', liquidation.slatCode? [liquidation.slatCode]: []);
        return plainLiquidation;
    },
    async createLiquidation({commit}, liquidation){
        try{
            const newLiquidation = singleResourceProcessor(await service.create(liquidation));
            commit('putLiquidation', {liquidation: newLiquidation, selectLiquidation: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editLiquidation({commit}, {liquidation, id}){
        try{
            const newLiquidation = singleResourceProcessor(await service.edit(id, liquidation));
            commit('putLiquidation', {liquidation: newLiquidation, selectLiquidation: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async getLabsBySlatCodeId({commit}, slatCodeId) {
        try {
            const {content: labs = []} = collectionResourceProcessor(await service.getLabsBySlatCode(slatCodeId));
            commit('setLabResults', labs);
        } catch (e) {
            console.log(e);
        }
    },
    async printReportLiquidation({commit},income,type)
    {
        try{
            const response= await service.printReportLiquidation(income,type);
            const {data = ""} = response;
            return data;
        }catch(e){
            errorProcessor(e);
        }
    },
    async refreshQuotationsByDate({commit}, date){
        try{
            const response= await elementService.quotationsByDate(date);
            const {content = ""} = collectionResourceProcessor(response);
            return content;
        }catch(e){
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectLiquidation(state, liquidation = null){
        state.selectedLiquidation = liquidation;
        state.liquidation = {};
    },
    setLiquidations(state, liquidations = []){
        state.liquidations = liquidations;
    },
    putLiquidation(state, { liquidation={}, selectLiquidation = false } = {}){
        state.liquidations = state.liquidations.map(l=>(l.id===liquidation.id)?liquidation:l);
        if(selectLiquidation){
            const {incomeId="none"} = liquidation;
            const {type="ORIGINAL"} = state.selectedLiquidation; 
            state.selectedLiquidation = {
                incomeId,
                type,
                date: Date.now()
            };
        }
    },
    setLiquidation(state, liquidation = {}){
        state.liquidation = liquidation;
    },
    setIncomes(state, incomes = []){
        state.incomes = incomes;
    },
    
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },



    setCooperatives(state, cooperatives = []){
        state.cooperatives = cooperatives;
    },
    setProviders(state, providers = []){
        state.providers = providers;
    },
    setOwners(state, owners = []){
        state.owners = owners;
    },
    setMineMouths(state, mineMouths = []){
        state.mineMouths = mineMouths;
    },
    setLabResults(state, results = []){
        state.labResults = results;
    },

    setSelection(state, selection = {}){
        state.selection = selection;
    },
    setMinerals(state, minerals = []){
        state.minerals = minerals;
    },
    setSlatCodes(state, slatCodes = []){
        state.slatCodes = slatCodes;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalLiquidations = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
<template>
  <router-view></router-view>
</template>

<script>
import {mapState} from 'vuex';
import UserLayout from '@/views/_layouts/UserLayout';
import EmptyLayout from '@/views/_layouts/EmptyLayout';

export default {
  name: 'App',
  props: {
    source: String,
  },
  components: {
    // EmptyLayout,
    // UserLayout
  },
  computed:{
    ...mapState({
      'layout': 'MAIN_LAYOUT'
    })
  }
};
</script>
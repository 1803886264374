import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/drivers', {
            params: queryParams
        });
    },
    create(driver){
        return Vue.axios.post('/drivers', driver);
    },
    edit(driverId, driver){
        return Vue.axios.put(`/drivers/${driverId}`, driver);
    },
    delete(driverId){
        return Vue.axios.delete(`/drivers/${driverId}`);
    }
};
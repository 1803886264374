import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/products', {
            params: queryParams
        });
    },
    create(product){
        return Vue.axios.post('/products', product);
    },
    edit(productId, product){
        return Vue.axios.put(`/products/${productId}`, product);
    },
    delete(productId){
        return Vue.axios.delete(`/products/${productId}`);
    },

    getMinerals(){
        return Vue.axios.get('/elements/minerals');
    }
};
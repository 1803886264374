import moment from "moment";
export const incomeToPlainMapper = (income = {}, isNew = true) => {
    if(isNew){
        return {
            kind: 'GRANEL',
            grossWeight: 0,
            containerWeight: 0,
            netWeight: 0,
            quantityBag: 0
        };
    }

    let date = income.weighingDate? new Date(income.weighingDate): new Date();
    const plainIncome = {
        collectionCenterId: income.collectionCenter?.id,
        ticketNumber: income.ticketNumber,
        loteInt:income.loteInt,
        platformId: income.platform?.id,
        providerId: income.provider?.id,
        slatCodeId: income.slatCode?.id,
        ownerId: income.owner?.id,
        cooperativeId: income.cooperative?.id,
        mineMouthId: income.mineMouth?.id,
        orderNumber: income.orderNumber,
        //weighingDate: new moment(income.weighingDate).format('YYYY-MM-DD'),
        weighingMachine: income.weighingMachine,
        observations: income.observations,
        driverId: income.driver?.id,
        mineralProductId: income.product?.id,
        truckId: income.truck?.id,
        kind: income.kind,
        grossWeight: income.grossWeight??0,
        containerWeight: income.containerWeight??0,
        netWeight: income.netWeight??0,
        quantityBag: income.quantityBag??0,
        weighingDate: date
    };
    const {id="none"} = income;
    if(id!=='none'){
        plainIncome.id = id;
    }
    return plainIncome;
};
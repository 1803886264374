import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/accounts', {
            params: queryParams
        });
    },
    create(account){
        return Vue.axios.post('/accounts', account);
    },
    edit(accountId, account){
        return Vue.axios.put(`/accounts/${accountId}`, account);
    },
    delete(accountId){
        return Vue.axios.delete(`/accounts/${accountId}`);
    },

    getSourceAccounts(){
        return Vue.axios.get('/accounts/sources');
    }
};
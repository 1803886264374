import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/slat-codes', {
            params: queryParams
        });
    },
    create(slatCode){
        return Vue.axios.post('/slat-codes', slatCode);
    },
    edit(slatCodeId, slatCodes){
        return Vue.axios.put(`/slat-codes/${slatCodeId}`, slatCodes);
    },
    delete(slatCodeId){
        return Vue.axios.delete(`/slat-codes/${slatCodeId}`);
    },
    generateCodes(cantidad){
        return Vue.axios.get(`/slat-codes/generate/${cantidad}`);
    },
    printCodes(values){
        return Vue.axios.get('/slat-codes/printCodes',{
            params: {codes:values.join(',')},
            responseType: "arraybuffer"
        },);
    }
};
import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/trucks.service';

const state = {
    pageableOptions: {
    },
    totalTrucks: 0,
    trucks: [],
    selectedTruck: null,
};

const getters = {
    truckIsSelected(state){
        return (state.selectedTruck !== null);
    },
    truckIsNew(state){
        return _.isEmpty(state.selectedTruck);
    },
    trucks(state){
        return state.trucks;//possible mapping
    }
};

const actions = {
    async getAllTrucks({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: trucks, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setTrucks', trucks);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createTruck({commit}, truck){
        try{
            const newTruck = singleResourceProcessor(await service.create(truck));
            commit('putTruck', {truck: newTruck, selectTruck: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editTruck({commit}, {truck, id}){
        try{
            const newTruck = singleResourceProcessor(await service.edit(id, truck));
            commit('putTruck', {truck: newTruck, selectTruck: true});
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectTruck(state, truck = null){
        state.selectedTruck = truck;
    },
    setTrucks(state, trucks = []){
        state.trucks = trucks;
    },
    putTruck(state, { truck={}, selectTruck = false } = {}){
        state.trucks = state.trucks.map(t=>(t.id===truck.id)?truck:t);
        if(selectTruck){
            state.selectedTruck = truck;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalTrucks = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/contributions', {
            params: queryParams
        });
    },
    create(contribution){
        return Vue.axios.post('/contributions', contribution);
    },
    edit(contributionId, contributions ){
        return Vue.axios.put(`/contributions/${contributionId}`, contributions);
    },
    delete(contributionId){
        return Vue.axios.delete(`/contributions/${contributionId}`);
    }
};
import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/cooperatives', {
            params: queryParams
        });
    },
    create(cooperative){
        return Vue.axios.post('/cooperatives', cooperative);
    },
    edit(cooperativeId, cooperatives){
        return Vue.axios.put(`/cooperatives/${cooperativeId}`, cooperatives);
    },
    delete(cooperativeId){
        return Vue.axios.delete(`/cooperatives/${cooperativeId}`);
    },
    getCooperativeContrib(cooperativeId) {
        return Vue.axios.get(`/cooperatives/contributions/${cooperativeId}`);
    },
    createCooperativeContributions(cooperativeContributions, cooperative){
        return Vue.axios.post('/cooperatives/createCooperativeContributions', cooperativeContributions, cooperative);
    }
};
import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/liquidations', {
            params: queryParams
        });
    },
    getShortIncomesBySearch(query){
        return Vue.axios.get('/slat-codes/search', {
            params: {q: query}
        });
    },
    getOriginalLiquidationByIncomeId(id){
        return Vue.axios.get(`/liquidations/by-income/${id}`, {
            params: {
                type: 'ORIGINAL'
            }
        });
    },
    getCopyLiquidationByIncomeId(id){
        return Vue.axios.get(`/liquidations/by-income/${id}`, {
            params: {
                type: 'COPY'
            }
        });
    },
    create(liquidation){
        return Vue.axios.post('/liquidations', liquidation);
    },
    edit(liquidationId, liquidation){
        return Vue.axios.put(`/liquidations/${liquidationId}`, liquidation);
    },
    getMinerals(){
        return Vue.axios.get('/elements/minerals');
    },
    getLabsBySlatCode(slatCodeId) {
        return Vue.axios.get(`/lab-requests/by-slat-code/${slatCodeId}`);
    },
    printReportLiquidation(income,typeLiqui){
        return Vue.axios.get(`/liquidations/printReportLiquidation/${income}`,{
            responseType: "arraybuffer",
            params: {
                type: typeLiqui
            }
        });
    }
};
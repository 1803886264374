import Vue from 'vue'
import VueRouter from 'vue-router'
import {USER_ROLE, ADMIN_ROLE} from "@/shared/utils/constants";
import {USER_LAYOUT} from '@/shared/utils/constants';

import routerGuard from '@/core/guards/router.guard';
import {EMPTY_LAYOUT} from "../shared/utils/constants";

Vue.use(VueRouter)

  const routes = [
    {
      path: '/home',
      redirect: '/'
    },
    {
      path: '/auth',
      component: ()=>import('@/views/_layouts/EmptyLayout.vue'),
      children: [
        {
          path: 'login',
          component: ()=>import('@/views/LoginPage.vue'),
          // meta: {
          //   roles: [ADMIN_ROLE, USER_ROLE],
          //   layout: USER_LAYOUT
          // }
        }
      ]
    },
    {
      path: '',
      component: ()=>import('@/views/_layouts/UserLayout.vue'),
      children: [
        {
          path: '/',
          name: 'home',
          component: ()=>import('@/views/HomePage'),
          meta: {
            roles: [ADMIN_ROLE, USER_ROLE],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/mineral-incomes',
          name: 'mineral.incomes',
          component: ()=>import('@/views/modules/incomes.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/liquidations',
          name: 'mineral.liquidations',
          component: ()=>import('@/views/modules/liquidations.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/mineral-outcomes',
          name: 'mineral.outcomes',
          component: ()=>import('@/views/modules/outcomes.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/mineral-outcomes/create',
          name: 'mineral.outcomes.create',
          component: ()=>import('@/views/modules/outcomes.view/Create'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/drivers',
          name: 'drivers',
          component: ()=>import('@/views/modules/drivers.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/trucks',
          name: 'trucks',
          component: ()=>import('@/views/modules/trucks.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/providers',
          name: 'providers',
          component: () => import('@/views/modules/providers.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/cooperatives',
          name: 'cooperatives',
          component: ()=>import('@/views/modules/cooperatives.module/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/elements',
          name: 'elements',
          component: ()=>import('@/views/modules/elements.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/products',
          name: 'products',
          component: ()=>import('@/views/modules/products.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/laboratories',
          name: 'laboratories',
          component: ()=>import('@/views/modules/laboratories.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/lab-requests',
          name: 'lab_requests',
          component: ()=>import('@/views/modules/labRequests.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/mineMouth',
          name: 'mineMouths',
          component: ()=>import('@/views/modules/mines.module/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/slatCode',
          name: 'slatCodes',
          component: ()=>import('@/views/modules/slatcodes.module/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/cashDesk',
          name: 'cashDesks',
          component: ()=>import('@/views/modules/cashDesk.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/contribution',
          name: 'contribution',
          component: ()=>import('@/views/modules/contributions.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/accounts',
          name: 'accounts',
          component: ()=>import('@/views/modules/accounts.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/transactions',
          name: 'transactions',
          component: ()=>import('@/views/modules/transactions.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/companie',
          name: 'companies',
          component: ()=>import('@/views/modules/companies.view/Index'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/users',
          name: 'users',
          component: ()=>import('@/views/modules/users.view/Index'),
            meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
        {
          path: '/reports/m02',
          name: 'report-m02',
          component: ()=>import('@/views/modules/reports.view/M02.vue'),
          meta: {
            roles: [],
            layout: USER_LAYOUT
          }
        },
      ]
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: ()=>import('@/views/UnauthorizedPage'),
      meta: {
        roles: []
      }
    },
    {
      path: '*',
      component: ()=>import('@/views/NotFoundPage')
    }
  ];

const router = new VueRouter({routes});

//gitrd);

export default router

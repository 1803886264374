import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/accounts.service';

const state = {
    accounts: [],
    selectedAccount: null,
    sourceAccounts: []
};

const getters = {
    accountIsSelected(state){
        return (state.selectedAccount !== null);
    },
    accountIsNew(state){
        return _.isEmpty(state.selectedAccount);
    },
    accounts(state){
        return state.accounts;//possible mapping
    },
    sourceAccounts(state){
        return state.sourceAccounts;
    }
};

const actions = {
    async getAllAccounts({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: accounts} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setAccounts', accounts);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createAccount({commit}, account){
        try{
            const newAccount = singleResourceProcessor(await service.create(account));
            commit('putAccount', {account: newAccount, selectAccount: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editAccount({commit}, {account, id}){
        try{
            const newAccount = singleResourceProcessor(await service.edit(id, account));
            commit('putAccount', {account: newAccount, selectAccount: true});
        }catch(e) {
            errorProcessor(e);
        }
    },

    async getSourceAccounts({commit}){
        try{
            const {content: accounts} = collectionResourceProcessor(await service.getSourceAccounts());
            commit('setSourceAccounts', accounts);
        }catch(e){
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectAccount(state, account = null){
        console.log('aaa', account);
        state.selectedAccount = account;
    },
    selectAccountById(state, id){
        const selectedAccount = state.accounts.find(account => account.id === id);
        state.selectedAccount = selectedAccount??null;
    },
    setAccounts(state, accounts = []){
        state.accounts = accounts;
    },
    putAccount(state, { account={}, selectAccount = false } = {}){
        state.accounts = state.accounts.map(d=>(d.id===account.id)?account:d);
        console.log("asss", account);
        if(selectAccount){
            state.selectedAccount = account;
        }
    },
    
    setSourceAccounts(state, accounts = []){
        state.sourceAccounts = accounts;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
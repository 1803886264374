import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/mines', {
            params: queryParams
        });
    },
    create(mineMouth){
        return Vue.axios.post('/mines', mineMouth);
    },
    edit(mineMouthId, mineMouth){
        return Vue.axios.put(`/mines/${mineMouthId}`, mineMouth);
    },
    getMunicipalitys(query) {
        return Vue.axios.get('/municipalitys/search', {
            params: {q:query}
        });
    },
    getLocationMunicipality(locationId){
        return Vue.axios.get(`/locations/locationMunicipality/${locationId}`);
    },
    getMiningConcessions(municipalityId){
        return Vue.axios.get(`/mining-concessions/municipality/${municipalityId}`)
    },
    getMineMouth(){
        return Vue.axios.get('')
    },
    delete(mineMouthId){
        return Vue.axios.delete(`/mines/${mineMouthId}`);
    },
    createMiningConcession(miningConcession){
        return Vue.axios.post('/mines/create-mining-concession', miningConcession);
    },
    getMineMining(miningConcessionId){
        return Vue.axios.get(`mines/mineMouthConcessions/${miningConcessionId}`);
    },
    createMineMouthConcession(mineMouth){
        return Vue.axios.post('/mines/create-mine-concession', mineMouth);
    }
};
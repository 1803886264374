<template>
  <v-app dark>
    <v-main>
      <v-container class="fill-height background-primary primary" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app dark>footer</v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'EmptyLayout',
  props: {
    source: String,
  },
  components: {

  },

  data: () => ({
    dialog: false,
  })
};
</script>

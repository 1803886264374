export const labRequestToPlainMapper = (labRequest = {}, isNew = true) => {
    if(isNew){
        return {};
    }
    const plainRequest = { };
    const {id="none"} = labRequest;
    if(id!=='none'){
        plainRequest.id = id;
    }
    return plainRequest;
};
import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/providers.service';

const state = {
    pageableOptions: {
    },
    totalProviders: 0,
    providers: [],
    selectedProvider: null,
};

const getters = {
    providerIsSelected(state){
        return (state.selectedProvider !== null);
    },
    providerIsNew(state){
        return _.isEmpty(state.selectedProvider);
    },
    providers(state){
        return state.providers;//possible mapping
    }
};

const actions = {
    async getAllProviders({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: providers, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setProviders', providers);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createProvider({commit}, provider){
        try{
            const newProvider = singleResourceProcessor(await service.create(provider));
            commit('putProvider', {provider: newProvider, selectProvider: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editProvider({commit}, {provider, id}){
        try{
            const newProvider = singleResourceProcessor(await service.edit(id, provider));
            commit('putProvider', {provider: newProvider, selectProvider: true});
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectProvider(state, provider = null){
        state.selectedProvider = provider;
    },
    setProviders(state, providers = []){
        state.providers = providers;
    },
    putProvider(state, { provider={}, selectProvider = false } = {}){
        state.providers = state.providers.map(p=>(p.id===provider.id)?provider:p);
        if(selectProvider){
            state.selectedProvider = provider;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalProviders = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/drivers.service';

const state = {
    pageableOptions: {
    },
    totalDrivers: 0,
    drivers: [],
    selectedDriver: null,
};

const getters = {
    driverIsSelected(state){
        return (state.selectedDriver !== null);
    },
    driverIsNew(state){
        return _.isEmpty(state.selectedDriver);
    },
    drivers(state){
        return state.drivers;//possible mapping
    }
};

const actions = {
    async getAllDrivers({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: drivers, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setDrivers', drivers);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createDriver({commit}, driver){
        try{
            const newDriver = singleResourceProcessor(await service.create(driver));
            commit('putDriver', {driver: newDriver, selectDriver: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editDriver({commit}, {driver, id}){
        try{
            const newDriver = singleResourceProcessor(await service.edit(id, driver));
            commit('putDriver', {driver: newDriver, selectDriver: true});
        }catch(e) {
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectDriver(state, driver = null){
        state.selectedDriver = driver;
    },
    selectDriverById(state, id){
        const selectedDriver = state.drivers.find(driver => driver.id === id);
        state.selectedDriver = selectedDriver??null;
    },
    setDrivers(state, drivers = []){
        state.drivers = drivers;
    },
    putDriver(state, { driver={}, selectDriver = false } = {}){
        state.drivers = state.drivers.map(d=>(d.id===driver.id)?driver:d);
        if(selectDriver){
            state.selectedDriver = driver;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalDrivers = totalElements;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
import _ from 'lodash';

import { collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';

import service  from '@/core/services/incomes.service';

const state = {
    exportable: [],
    form: {
        items: []
    }
};

const getters = {
    filteredExportable(state){
        return state.exportable.map(it=>{
            return {
                ...it
            };
        });
    }
};

const actions = {
    async getExportable({commit, state}){
        try{
            commit('setExportable', []);
            const resp = await service.getExportable();
            commit('setExportable', resp.data??[]);
        }catch(e){
            errorProcessor(e);
        }
    },
    async findAndAddSelection({commit, state}, code) {
        try {
            const resp = await service.findByCode(code);
            const { data } = resp;
            commit('addToExports', data);
            alert("Agregado correctamente");
        } catch (e) {

            // errorProcessor(e);
            alert("Registro no encontrado!!!");
        }
    }
};

const mutations = {
    addToExports(state, item){
        console.log(item);
        const index = state.form.items.findIndex((i) => i.id === item.id);
        if(index < 0){
            state.form.items.push(item);
        }
    },
    setExportable(state, exportable = []){
        state.exportable = exportable.map(it=>{
            return {
                ...it,
                selected: state.form.items.find(i=>i.id === it.id)? true: false
            }
        });
    },
    setExportableSelection(state, {item_id, option}){
        console.log(item_id, option)
        const index = state.exportable.findIndex((i) => i.id === item_id);
        if (index>=0) {
            state.exportable[index] = {...state.exportable[index], selected: !!option};
        }
    },
    setSelection(state, itemsToSelect = []){
        state.form.items = state.exportable.filter(it=>it.selected);
    },
    removeItem(state, item_id){
        state.form.items = state.form.items.filter(it=>it.id!==item_id);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
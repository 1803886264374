import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/cooperatives.service';

const state = {
    pageableOptions: {
    },
    totalCooperatives: 0,
    cooperatives: [],
    selectedCooperative: null,
    contributions:[],
    cooperativeContributions:[],
    selectedComponent:null,
};

const getters = {
    cooperativeIsSelected(state){
        return (state.selectedCooperative !== null);
    },
    cooperativeIsNew(state){
        return _.isEmpty(state.selectedCooperative);
    },
    cooperatives(state){
        return state.cooperatives;//possible mapping
    }
};

const actions = {
    async getAllCooperatives({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: cooperatives, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            console.log("valores cooperativa", cooperatives);
            commit('setCooperatives', cooperatives);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createCooperative({commit}, cooperative){
        try{
            const newCooperative = singleResourceProcessor(await service.create(cooperative));
            commit('putCooperative', {cooperative: newCooperative, selectCooperative: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editCooperative({commit}, {cooperative, id}){
        try{
            const newCooperative = singleResourceProcessor(await service.edit(id, cooperative));
            commit('putCooperative', {cooperative: newCooperative, selectCooperative: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async getCooperativeContribution({commit, state}){
            const {selectedCooperative} = state;
            const {id:cooperativeId} = selectedCooperative;
            const response =  await service.getCooperativeContrib(cooperativeId);
            const {contributions,cooperativeContributions} = response.data._content;
            commit('setContributions', contributions);
            commit('setCooperativeContributions', cooperativeContributions);
    },
    async createCooperativeContribution({commit,state}){
        try{
            const response = await service.createCooperativeContributions({ cooperativeContributions:state.cooperativeContributions,id:state.selectedCooperative.id});
            const {contributions,cooperativeContributions} = response.data._content;
            commit('setContributions', contributions);
            commit('setCooperativeContributions', cooperativeContributions);
        }catch(e) {
            errorProcessor(e);
        }
    }
};
const mutations = {
    selectCooperative(state, cooperative = null){
        state.selectedCooperative = cooperative;
    },
    selectCooperativeById(state, id){
        const selectedCooperative = state.cooperatives.find(cooperative => cooperative.id === id);
        state.selectedCooperative = selectedCooperative??null;
    },
    setCooperatives(state, cooperatives = []){
        state.cooperatives = cooperatives;
    },
    putCooperative(state, { cooperative={}, selectCooperative = false } = {}){
        state.cooperatives = state.cooperatives.map(d=>(d.id===cooperative.id)?cooperative:d);
        if(selectCooperative){
            state.selectedCooperative = cooperative;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalCooperatives = totalElements;
    },
    setContributions(state, contributions) {
        state.contributions = contributions;
    },
    setCooperativeContributions(state, cooperativeContributions) {
        state.cooperativeContributions = cooperativeContributions;
    },
    setSelectedComponent(state, newComponent){
        state.selectedComponent = newComponent;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};

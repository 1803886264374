import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/trucks', {
            params: queryParams
        });
    },
    create(truck){
        return Vue.axios.post('/trucks', truck);
    },
    edit(truckId, truck){
        return Vue.axios.put(`/trucks/${truckId}`, truck);
    },
    delete(truckId){
        return Vue.axios.delete(`/trucks/${truckId}`);
    }
};
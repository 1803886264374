import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/providers', {
            params: queryParams
        });
    },
    create(provider){
        return Vue.axios.post('/providers', provider);
    },
    edit(providerId, provider){
        return Vue.axios.put(`/providers/${providerId}`, provider);
    },
    delete(providerId){
        return Vue.axios.delete(`/providers/${providerId}`);
    }
};
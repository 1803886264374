import _ from 'lodash';

import { singleResourceProcessor, collectionResourceProcessor, errorProcessor } from '@/shared/utils/response-processors.helper';
import { pageableToQueryParams } from '@/shared/utils/converters.helper';

import service  from '@/core/services/incomes.service';

import { incomeToPlainMapper } from '@/core/mappers/incomeMappers';

const state = {
    pageableOptions: {
    },
    provider:{
        id:null,
        documentId:'',
        name:''
    },
    totalIncomes: 0,
    incomes: [],
    selectedIncome: null,

    collectionCenters: [],
    platforms: [],
    providers: [],
    owners: [],
    cooperatives: [],
    mineMouths: [],
    trucks: [],
    drivers: [],
    slatCodes: [],
    products: [],
    municipalitys: []
};

const getters = {
    incomeIsSelected(state){
        return (state.selectedIncome !== null);
    },
    incomeIsNew(state){
        return _.isEmpty(state.selectedIncome);
    },
    incomes(state){
        return state.incomes;//possible mapping
    },

    collectionCenters(state){
        return state.collectionCenters.map(cc=>({
            text: cc.name,
            value: cc.id
        }));
    },
    platforms(state){
        return state.platforms.map(p=>({
            text: p.name,
            value: p.id
        }));
    },
    providers(state){
        return state.providers.map(p=>({
            text: p.name,
            value: p.id
        }));
    },
    owners(state){
        return state.owners.map(p=>({
            text: p.name,
            value: p.id
        }));
    },
    products(state){
        return state.products.map(p=>({
            text: p.name,
            value: p.id
        }));
    },
    cooperatives(state){
        return state.cooperatives.map(c=>({
            text: c.name,
            value: c.id
        }));
    },
    trucks(state){
        return state.trucks.map(t=>({
            text: t.plaque,
            value: t.id
        }));
    },
    drivers(state){
        return state.drivers.map(d=>({
            text: d.fullname,
            value: d.id
        }));
    },
    mineMouths(state){
        return state.mineMouths.map(mm=>({
            text: mm.name,
            value: mm.id
        }));
    },
    slatCodes(state){
        return state.slatCodes.map(sc=>({
            text: sc.code,
            value: sc.id
        }));
    },
    municipalitys(state){
        return state.municipalitys.map(mun=>({
            text: mun.name,
            value: mun.id
        }));
    }
};

const actions = {
    async getAllIncomes({commit, state}){
        try{
            const queryParams = pageableToQueryParams(state.pageableOptions??{});
            const {content: incomes, meta} = collectionResourceProcessor(await service.getAll(queryParams));
            commit('setIncomes', incomes);
            commit('setMetaData', meta);
        }catch(e){
            errorProcessor(e);
        }
    },
    async createIncome({commit}, income){
        try{
            const newIncome = singleResourceProcessor(await service.create(income));
            commit('putIncome', {income: newIncome, selectIncome: true});
        }catch(e) {
            errorProcessor(e);
        }
    },
    async editIncome({commit}, {income, id}){
        try{
            const newIncome = singleResourceProcessor(await service.edit(id, income));
            commit('putIncome', {income: newIncome, selectIncome: true});
        }catch(e) {
            errorProcessor(e);
        }
    },

    async getCollectionCenters({commit}, query){
        try{
            const {content: collectionCenters} = collectionResourceProcessor(await service.getCollectionCenters(query));
            commit('setCollectionCenters', collectionCenters);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getPlatforms({commit}, query){
        try{
            const {content: platforms} = collectionResourceProcessor(await service.getPlatforms(query));
            commit('setPlatforms', platforms);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getProviders({commit}, query){
        try{
            const {content: providers} = collectionResourceProcessor(await service.getProviders(query));
            commit('setProviders', providers);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getOwners({commit}, query){
        try{
            const {content: owners} = collectionResourceProcessor(await service.getProviders(query));
            commit('setOwners', owners);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getCooperatives({commit}, query){
        try{
            const {content: cooperatives} = collectionResourceProcessor(await service.getCooperatives(query));
            commit('setCooperatives', cooperatives);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getMineMouths({commit}, query){
        try{
            const {content: mineMouths} = collectionResourceProcessor(await service.getMineMouths(query));
            console.log(mineMouths);
            commit('setMineMouths', mineMouths);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getDrivers({commit}, query){
        try{
            const {content: drivers} = collectionResourceProcessor(await service.getDrivers(query));
            commit('setDrivers', drivers);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getTrucks({commit}, query){
        try{
            const {content: trucks} = collectionResourceProcessor(await service.getTrucks(query));
            commit('setTrucks', trucks);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getProducts({commit}){
        try{
            const {content: products} = collectionResourceProcessor(await service.getProducts());
            commit('setProducts', products);
        }catch(e){
            errorProcessor(e);
        }
    },
    async getMunicipalitys({commit}, query){
        try{
            const {content: municipalitys} = collectionResourceProcessor(await service.getMunicipalitys(query));
            commit('setMunicipalitys', municipalitys);
        }catch(e){
            errorProcessor(e);
            console.log(e);
        }
    },
    async getPlainIncome({commit}, {income={}, isNew=false}){
        try{
            const plainIncome = incomeToPlainMapper(income, isNew);
            const selectedSlatCode = income.slatCode;
            let slatCodes = [selectedSlatCode];
            if(!selectedSlatCode){
                const { content } = collectionResourceProcessor(await service.getAvailableSlatCodes());
                slatCodes = content;
            }
            commit('setCollectionCenters', income.collectionCenter?[income.collectionCenter]:[]);
            commit('setPlatforms', income.platform?[income.platform]:[]);
            commit('setProviders', income.provider?[income.provider]:[]);
            commit('setOwners', income.owner?[income.owner]:[]);
            commit('setMineMouths', income.mineMouth?[income.mineMouth]:[]);
            commit('setDrivers', income.driver?[income.driver]:[]);
            commit('setTrucks', income.truck?[income.truck]:[]);
            commit('setCooperatives', income.cooperative?[income.cooperative]:[]);
            commit('setSlatCodes', slatCodes);
            return plainIncome;
        }catch(e){
            errorProcessor(e);
        }
    },
    async printReportIncome({commit},income)
    {
        try{
            const response= await service.printReportIncome(income);
            const {data = ""} = response;
            return data;
        }catch(e){
            errorProcessor(e);
        }
    },
    async saveProvider({commit},provider)
    {
        try{
            const response= await service.saveProvider(provider);
            const {data = ""} = response;
            //console.log("vvv",);
            commit('setProviders', response.data._content.providers);
            return data;
        }catch(e){
            errorProcessor(e);
        }
    },
    async saveOwner({commit},owner)
    {
        try{
            const response= await service.saveProvider(owner);
            const {data = ""} = response;
            commit('setOwners', response.data._content.providers);
            return data;
        }catch(e){
            errorProcessor(e);
        }
    },
    async saveCooperative({commit},cooperative)
    {
        try{
            const response = await service.saveCooperative(cooperative);
            const {data = ""} = response;
            commit('setCooperatives', response.data._content.cooperatives)
            return data;
        }catch(e){
            errorProcessor(e);
        }
    },
    async saveTruck({commit},truck)
    {
        try{
            const response = await service.saveTruck(truck);
            const {data = ""} = response;
            commit('setTrucks', response.data._content.trucks)
            return data;
        }catch(e){
            errorProcessor(e);
        }
    },
    async saveDriver({commit},driver)
    {
        try{
            const response = await service.saveDriver(driver);
            const {data = ""} = response;
            commit('setDrivers', response.data._content.drivers)
            return data;
        }catch(e){
            errorProcessor(e);
        }
    },
    async saveMineMouth({commit}, {mineMouth, id}){
        try{
            const response = await service.saveMine(mineMouth,id);
            const {data = ""} = response;
            commit('setMineMouths', response.data._content.mineMouths);
            return data;
        }catch(e){
            errorProcessor(e);
        }
    }
};

const mutations = {
    selectIncome(state, income = null){
        state.selectedIncome = income;
    },
    setIncomes(state, incomes = []){
        state.incomes = incomes;
    },
    putIncome(state, { income={}, selectIncome = false } = {}){
        state.incomes = state.incomes.map(i=>(i.id===income.id)?income:i);
        if(selectIncome){
            state.selectedIncome = income;
        }
    },
    setPageableOptions(state, options){
        state.pageableOptions = options;
    },
    setMetaData(state, meta){
        const { totalElements = 0 } = meta;
        state.totalIncomes = totalElements;
    },
    setCollectionCenters(state, collectionCenters = []){
        state.collectionCenters = collectionCenters;
    },
    setPlatforms(state, platforms = []){
        state.platforms = platforms;
    },
    setProviders(state, providers = []){
        state.providers = providers;
    },
    setOwners(state, owners = []){
        state.owners = owners;
    },
    setProducts(state, products = []){
        state.products = products;
    },
    setMunicipalitys(state, municipalitys = []){
        state.municipalitys = municipalitys;
    },
    setCooperatives(state, cooperatives = []){
        state.cooperatives = cooperatives;
    },
    setMineMouths(state, mineMouths = []){
        state.mineMouths = mineMouths;
    },
    setDrivers(state, drivers = []){
        state.drivers = drivers;
    },
    setTrucks(state, trucks = []){
        state.trucks = trucks;
    },
    setSlatCodes(state, slatCodes = []){
        state.slatCodes = slatCodes;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
import Vue from 'vue'
import VueIziToast from 'vue-izitoast';
import './plugins/fontawesome'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';

import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
import { TextBoxPlugin } from '@syncfusion/ej2-vue-inputs';
import { TreeGridPlugin } from '@syncfusion/ej2-vue-treegrid';
import { ComboBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { PdfViewerPlugin } from '@syncfusion/ej2-vue-pdfviewer';
import { enableRipple } from '@syncfusion/ej2-base';
import _ from 'lodash';


enableRipple(true);
Vue.use(ButtonPlugin);
Vue.use(TextBoxPlugin);
Vue.use(TreeGridPlugin);
Vue.use(ComboBoxPlugin);
Vue.use(PdfViewerPlugin);
Vue.filter("round", value => {
  const possibleNumber = isNaN(value)? 0: _.toNumber(value);
  return _.round(possibleNumber, 2);
});

require('./assets/custom.css');
import 'izitoast/dist/css/iziToast.min.css';
Vue.config.productionTip = false
Vue.use(VueIziToast);
export const bus = new Vue();
new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
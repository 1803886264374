import moment from "moment";

export const liquidationToPlainMapper = (liquidation = {}, isNew = true) => {
    console.log("--->", liquidation.date);
    const {
        id = "none",
        wetness=0, dryWeight=0, wetWeight=0,
        priceTotalAmountDls=0, priceTotalAmountBs=0, appreciationAmount=0, 
        grossCompensation=0, percentageCompensationDiscount = 0, 
        valueCompensationDiscount =0, netCompensation = 0,
        totalAmount=0, payInAdvanceAmount=0, contributionsAmount=0, royaltyAmount=0,
        totalDiscountAmount=0, payableAmount=0, 
        type = 'ORIGINAL', royaltyType="PERCENTAGE",
        royaltyPercentage = 0, royaltyQuotation = 0,
        royaltyQuotationDls = 6.96,
        date
        } = liquidation;

    const contributions = (liquidation.contributions??[]).map(c=>({
        contributionId: c.contribution?.id,
        name: c.contribution?.name,
        percentage: c.percentage??0,
        value: c.value??0
    }));

    const elements = (liquidation.elements??[]).map(e=>({
        elementId: e.element?.id,
        elementName: e.element?.name,
        elementSymbol: e.mineral?.symbol,
        elementGradeLab: e.gradeLab??0,
        elementGradeAccord: e.gradeAccord??0,
        elementPrice: e.price??0,
        elementQuotation: e.quotation??0,
        elementAliquot: e.aliquot??0,
        subTotalDls: e.subTotalDls??0,
        subTotalBs: e.subTotalBs??0
    }));

    let shortDate = new moment(date);
    if(!shortDate.isValid()){
        shortDate = new moment();
    }

    const plainLiquidation = {
        cooperativeId: liquidation.cooperative?.id,
        weighingDate: liquidation.income?.weighingDate,
        ownerId: liquidation.owner?.id,
        mineMouthId: liquidation.mineMouth?.id,
        slatCodeId: liquidation.slatCode?.id,
        date: shortDate.format('YYYY-MM-DD'),
        wetness,
        wetWeight,
        dryWeight,
        priceTotalAmountDls, 
        priceTotalAmountBs,
        appreciationAmount,
        grossCompensation,
        netCompensation,
        percentageCompensationDiscount,
        valueCompensationDiscount,
        totalAmount,
        payInAdvanceAmount, 
        contributionsAmount,
        royaltyAmount,
        totalDiscountAmount, 
        payableAmount, 
        type,

        royaltyType,
        royaltyPercentage,
        royaltyQuotation,
        royaltyQuotationDls,
        contributions: contributions,
        elements: elements
    };

    if(id !== 'none'){
        plainLiquidation.id = id;
    }

    return plainLiquidation;
};
import Vue from 'vue';

export default {
    getAll(queryParams = {}){
        return Vue.axios.get('/users', {
            params: queryParams
        });
    },
    create(user){
        return Vue.axios.post('/users', user);
    },
    edit(userId, user){
        return Vue.axios.put(`/users/${userId}`, user);
    },

    getRoles(){
        return Vue.axios.get('/roles');
    }
};